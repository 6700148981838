.comunicare-widget {
    cursor: grab;
    margin: 5px auto;
    padding:0 !important;
    
    .mat-card-title {
        margin-bottom: 0px !important;
    }

    &.cdk-drag-preview {
        height: auto !important;
        border: 1px solid #666;

        .widget-header {
            margin: 0;
            padding: 0;
        }

        .widget-content {
            display: none;
        }
    }

    &.cdk-drag-placeholder {
        border: 2px dashed #666;
        .widget-content {
            display: none;
        }
    }
    
    .mat-card-header {
        background-color:#86afcf;
        color: white;
        padding:5px;
        border-radius: 3px 3px 0px 0px;
        height: auto;
    }

    .mat-card-content {
        padding: 5px !important;
    }

    .toolbar {
        border-top: 0px !important;
    }

    .appointments-table {
        width: 100%;
    }

    .custom-widget-font {
        font-size: 12px !important;
        font-weight: 400 !important;
    }

    .custom-widget-title {
        font-size: 16px !important;
    }

    .mat-paginator-container { 
        min-height: 40px !important;
        background-color: whitesmoke;
        border-top: 1px solid #E0E0E0;
    }

    table {
        width: 100% !important;

        .mat-cell {
            font-size: 12px;
            ;
        }
    
        tr.mat-header-row {
            height: 25px !important;
        }
    }
}

::ng-deep .mat-paginator-container { 
    min-height: 40px !important;
}